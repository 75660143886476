/********** Global Css ***************/

* {
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', Helvetica;
}

a {
    display: inline-block;
}

a:focus,
.btn:focus {
    outline: 0px;
}

ol,
ul {
    list-style: none;
}

.wrapper {
    /* overflow-x: hidden; */
    height: 100%;
    width: 100%;
    min-width: 1000px;
}

.clearfix:after,
.clearfix:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

html,
body,
.mainConent,
.main,
.content,
.tabContent {
    /*min-height: 100%;*/
    width: 100%;
    overflow: hidden;
}

body {
    overflow-x: hidden;
}
.text-center{
    text-align: center;
}


::-webkit-scrollbar {
    width: 5px;
    left: 0;           
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.btn {
    width: 170px;
    height: 46px;
    border-radius: 35px;
    border: 0px;
    color: #fff;
    background: #489642;
    font-size: 20px;
    font-weight: bold;
    border: 0px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .5s ease;
}

.search-btn:hover {
    background-color: #5dbf56;
}


p {
    font-size: 18px;
    line-height: 32px;
    color: #3D3642;
}

h1 {
    font-size: 20px;
    line-height: 27px;
    margin: 0px;
    font-weight: 600;
    color: #fff;
}

.mainConent {
    padding-left: 30px;
    padding-right: 30px;
    transition: all .5s ease;
    width: 100%;
    box-sizing: border-box;
}

.content {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
}

.contentSec {
    margin: 0px auto;
    max-width: 680px;
}

.content.active {
    opacity: 1;
    visibility: visible;
    /* height: 100%; */
}

.mainPage .tabContent,
.main {
    display: none;
}

.mainPage .main {
    display: block;
}

.tabContent {
    display: block;
    position: relative;
}

.mainPage .sideMenu {
    left: -80px;
}

.mainPage .mainConent {
    padding-left: 16px;
}

.blockSec {
    border: 1px solid #8EB3BD;
    box-shadow: 0px 0px 10px 10px #0000000a inset;
    margin: 10px;
    position: relative;
}


.logo {
    display: inline-block;
    width: 55px;
    height: 55px;
    margin: 0px auto 100% !important;
}

.logo img {
    width: 45px;
    margin: 2.5px;
}


/*-------- Main Page -----------*/

.header {
    margin: 0px 0px 170px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #c4c4c4;
}

.search_sec {
    max-width: 764px;
    margin: 0px auto 57px;
    text-align: center;
}

.contentBlock {
    max-width: 680px;
    margin: 0px auto;
}

.logoTxt {
    max-width: 155.1px;
    width: 100%;
}

.logoTxt img {
    max-width: 100%;
}

.timeSec {
    font-size: 20px;
    line-height: 27px;
    color: #B4B4B4;
    float: right;
    display: inline-block;
    margin: 0px
}

.search {
    height: 56px;
    padding: 0px 18px;
    font-size: 20px;
    line-height: 27px;
    color: #383838;
    border: 1px solid #C6C6C6;
    margin-bottom: 28px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 36px;
    font-weight: normal;
    box-shadow: 0 0 8px 1px #00000045;
}

.search:focus {
    outline: 0px;
}


.closed {
    display: none;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 21px;
    background: #FFA3A3;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    color: #fff;
    text-decoration: none;    
}

/************  PDF Upload  ****************/

.fileUpload_sec {
    padding: 52px 0px;
}

.file-upload {
    text-align: center;
    font-size: 14px;
    max-width: 500px;
    width: 100%;
    margin: 0px auto;
    color: #9FA9BA;
}

.file-upload .file-select {
    background: #FFFFFF;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.file-upload .file-select .file-select-button {
    padding: 0 5px;
    display: inline-block;
    height: 24px;
    line-height: 24px;
}

.file-upload .file-select .file-select-name {
    line-height: 32px;
    display: inline-block;
    padding: 0px 0px;
}

.file-upload .file-select input[type=file] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
    opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
    cursor: default;
    display: block;
    border: 2px solid #dce4ec;
    color: #34495e;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    text-align: left;
    background: #FFFFFF;
    overflow: hidden;
    position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
    background: #dce4ec;
    color: #666666;
    padding: 0 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
    line-height: 40px;
    display: inline-block;
    padding: 0 10px;
}

/********* Footer ************/

.footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 100%;
    height: 24px;
    background:white;
}

.footer ul {
    float: right;
}

.terms_policy_sec ul li {
    float: left;
}

.terms_policy_sec ul li a {
    display: inline-block;
    color: #B4B4B4;
    line-height: 19px;
    text-decoration: none;
}

.terms_policy_sec ul li a {
    border-right: 1px solid #B4B4B4;
    padding-right: 5px;
    margin-right: 5px;
}

.terms_policy_sec ul li:last-child a {
    border-right: 0px;
    padding-right: 0px;
    margin-right: 0px;
}


/************* Media Query *****************/
@media (max-width:1200px){
     html, body, .mainConent, .main, .content, .tabContent,.wrapper  {
      overflow-x:auto;
  }
}

@media (max-height:720px){
    .header {
          margin: 0px 0px 70px;
          }
          .search_sec {
          margin: 0px auto 35px;

      }
}

/* new Mandeep */
 
.btn, button, input[type="file"]{
	cursor:pointer;
 }
 #zip .blockSec {
    margin: auto;
    position: relative;
    max-width: 480px;
    height: 185px;
    background: #70B0F1;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 30px;
}
.fileUpload_sec {
    padding: 20px;
    background: transparent;
    border: 1px dashed #fff;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    border-radius: 24px;
}
.file-upload .file-select {
    overflow: hidden;
    position: relative;
    text-align: center;
    color: #fff;
	background:transparent;
}
.choosedifffile{
	opacity:.6;
}
.choosedifffile img{
	height: 14px;
}

.convert-zip{
    display: flex;
    justify-content: space-between;
	align-items:center;
}
.convert-zip strong{
	color:#fff;
}
.convertzip{
	padding: 6px 10px;
	background: transparent;
	border: 1px dashed #fff;
	box-sizing: border-box;
	height:100%;
	border-radius: 24px;
}

.step-2 .file-upload{
	margin: 0;
    max-width: none;
    width: auto;
}

.centerText{
	display: flex;
    flex-direction: column;
    align-items: center;
	
}
.file-sec{
	margin: 24px 0;
}
.FileLoad {
    font-size: 14px;
    color: #fff;
	display: flex;
    align-items: center;
}
.FileLoad img{
	margin-right:10px;
}
.convert-btn{
	width: 140px;
    height: 48px;
    background: #0CA71C;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 14px;
}
.Cancel{
	color: #fff;
	opacity: .6;
	background: transparent;
	border: transparent;
	position: absolute;
	right: 15px;
	bottom: 10px;
	text-decoration: none;
}

.zip-step1 h2, .zip-step2 h2{
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 14px;
	text-align: center;
}

.zip-step1{
	display:block;
}

.zip-step2{
	display:none;
}