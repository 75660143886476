* {
  margin: 0;
  padding: 0;
  font-family: Poppins, Helvetica;
}

a {
  display: inline-block;
}

a:focus, .btn:focus {
  outline: 0;
}

ol, ul {
  list-style: none;
}

.wrapper {
  width: 100%;
  min-width: 1000px;
  height: 100%;
}

.clearfix:after, .clearfix:before {
  visibility: hidden;
  content: " ";
  clear: both;
  height: 0;
  font-size: 0;
  display: block;
}

html, body, .mainConent, .main, .content, .tabContent {
  width: 100%;
  overflow: hidden;
}

body {
  overflow-x: hidden;
}

.text-center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
  left: 0;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #489642;
  border: 0;
  border-radius: 35px;
  width: 170px;
  height: 46px;
  font-size: 20px;
  font-weight: bold;
  transition: all .5s;
}

.search-btn:hover {
  background-color: #5dbf56;
}

p {
  color: #3d3642;
  font-size: 18px;
  line-height: 32px;
}

h1 {
  color: #fff;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.mainConent {
  box-sizing: border-box;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  transition: all .5s;
}

.content {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s;
}

.contentSec {
  max-width: 680px;
  margin: 0 auto;
}

.content.active {
  opacity: 1;
  visibility: visible;
}

.mainPage .tabContent, .main {
  display: none;
}

.mainPage .main {
  display: block;
}

.tabContent {
  display: block;
  position: relative;
}

.mainPage .sideMenu {
  left: -80px;
}

.mainPage .mainConent {
  padding-left: 16px;
}

.blockSec {
  border: 1px solid #8eb3bd;
  margin: 10px;
  position: relative;
  box-shadow: inset 0 0 10px 10px #0000000a;
}

.logo {
  width: 55px;
  height: 55px;
  display: inline-block;
  margin: 0 auto 100% !important;
}

.logo img {
  width: 45px;
  margin: 2.5px;
}

.header {
  color: #c4c4c4;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 170px;
  padding-top: 20px;
  display: flex;
}

.search_sec {
  text-align: center;
  max-width: 764px;
  margin: 0 auto 57px;
}

.contentBlock {
  max-width: 680px;
  margin: 0 auto;
}

.logoTxt {
  width: 100%;
  max-width: 155.1px;
}

.logoTxt img {
  max-width: 100%;
}

.timeSec {
  color: #b4b4b4;
  float: right;
  margin: 0;
  font-size: 20px;
  line-height: 27px;
  display: inline-block;
}

.search {
  color: #383838;
  box-sizing: border-box;
  border: 1px solid #c6c6c6;
  border-radius: 36px;
  width: 100%;
  height: 56px;
  margin-bottom: 28px;
  padding: 0 18px;
  font-size: 20px;
  font-weight: normal;
  line-height: 27px;
  box-shadow: 0 0 8px 1px #00000045;
}

.search:focus {
  outline: 0;
}

.closed {
  text-align: center;
  color: #fff;
  background: #ffa3a3;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 21px;
  text-decoration: none;
  display: none;
}

.fileUpload_sec {
  padding: 52px 0;
}

.file-upload {
  text-align: center;
  color: #9fa9ba;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  font-size: 14px;
}

.file-upload .file-select {
  text-align: center;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.file-upload .file-select .file-select-button {
  height: 24px;
  padding: 0 5px;
  line-height: 24px;
  display: inline-block;
}

.file-upload .file-select .file-select-name {
  padding: 0;
  line-height: 32px;
  display: inline-block;
}

.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity= 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-upload .file-select.file-select-disabled {
  opacity: .65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  color: #34495e;
  cursor: pointer;
  text-align: left;
  background: #fff;
  border: 2px solid #dce4ec;
  height: 40px;
  margin-top: 5px;
  line-height: 40px;
  display: block;
  position: relative;
  overflow: hidden;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  color: #666;
  background: #dce4ec;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
  display: inline-block;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  padding: 0 10px;
  line-height: 40px;
  display: inline-block;
}

.footer {
  background: #fff;
  width: 100%;
  height: 24px;
  position: absolute;
  bottom: 0;
  right: 20px;
}

.footer ul {
  float: right;
}

.terms_policy_sec ul li {
  float: left;
}

.terms_policy_sec ul li a {
  color: #b4b4b4;
  border-right: 1px solid #b4b4b4;
  margin-right: 5px;
  padding-right: 5px;
  line-height: 19px;
  text-decoration: none;
  display: inline-block;
}

.terms_policy_sec ul li:last-child a {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}

@media (width <= 1200px) {
  html, body, .mainConent, .main, .content, .tabContent, .wrapper {
    overflow-x: auto;
  }
}

@media (height <= 720px) {
  .header {
    margin: 0 0 70px;
  }

  .search_sec {
    margin: 0 auto 35px;
  }
}

.btn, button, input[type="file"] {
  cursor: pointer;
}

#zip .blockSec {
  box-sizing: border-box;
  background: #70b0f1;
  border-radius: 30px;
  max-width: 480px;
  height: 185px;
  margin: auto;
  padding: 6px;
  position: relative;
}

.fileUpload_sec {
  box-sizing: border-box;
  background: none;
  border: 1px dashed #fff;
  border-radius: 24px;
  align-items: center;
  height: 100%;
  padding: 20px;
  display: flex;
}

.file-upload .file-select {
  text-align: center;
  color: #fff;
  background: none;
  position: relative;
  overflow: hidden;
}

.choosedifffile {
  opacity: .6;
}

.choosedifffile img {
  height: 14px;
}

.convert-zip {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.convert-zip strong {
  color: #fff;
}

.convertzip {
  box-sizing: border-box;
  background: none;
  border: 1px dashed #fff;
  border-radius: 24px;
  height: 100%;
  padding: 6px 10px;
}

.step-2 .file-upload {
  width: auto;
  max-width: none;
  margin: 0;
}

.centerText {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.file-sec {
  margin: 24px 0;
}

.FileLoad {
  color: #fff;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.FileLoad img {
  margin-right: 10px;
}

.convert-btn {
  color: #fff;
  background: #0ca71c;
  border: none;
  border-radius: 30px;
  width: 140px;
  height: 48px;
  font-size: 14px;
}

.Cancel {
  color: #fff;
  opacity: .6;
  background: none;
  border: #0000;
  text-decoration: none;
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.zip-step1 h2, .zip-step2 h2 {
  text-align: center;
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: 600;
}

.zip-step1 {
  display: block;
}

.zip-step2 {
  display: none;
}

/*# sourceMappingURL=index.e39d3b64.css.map */
